var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-page',{attrs:{"name":"manual"}},[_c('f7-navbar',{attrs:{"title":"Lieferadresse","back-link":""}},[_c('f7-subnavbar',{attrs:{"inner":false}},[_c('f7-searchbar',{attrs:{"placeholder":"suchen","search-container":".virtual-list","search-item":"li","search-in":".item-title","disable-button":false},on:{"searchbar:search":_vm.setSearchString}})],1)],1),_vm._v(" "),(_vm.searchString === '')?_c('f7-list',{staticStyle:{"margin-bottom":"0px"},attrs:{"media-list":""}},[_c('ul',[(
                    _vm.$root.$data.is_reseller == false ||
                        typeof _vm.$root.$data.is_reseller == 'undefined'
                )?_c('f7-list-item',{attrs:{"title":"Favoriten","group-title":""}}):_c('f7-list-item',{attrs:{"title":"Meine Adresse(n)","group-title":""}}),_vm._v(" "),_vm._l((_vm.favorites),function(item,index){return _c('f7-list-item',{key:index,style:(("top: " + (_vm.vlData.topPosition) + "px")),attrs:{"title":item.name1,"subtitle":item.address1 + ', ' + item.postalcode + ' ' + item.place,"link":""},on:{"click":function($event){return _vm.addCustomer(item.id)}}})})],2)]):_vm._e(),_vm._v(" "),(_vm.$root.$data.is_reseller == false || typeof _vm.$root.$data.is_reseller == 'undefined')?_c('f7-list',{attrs:{"media-list":"","virtual-list":"","virtual-list-params":{
            items: _vm.items,
            searchAll: _vm.searchAll,
            renderExternal: _vm.renderExternal,
            height: _vm.$theme.ios ? 69 : _vm.$theme.md ? 73 : 73,
        }}},[_c('ul',[_c('f7-list-item',{attrs:{"title":"Alle Shops","group-title":""}}),_vm._v(" "),_vm._l((_vm.vlData.items),function(item,index){return _c('f7-list-item',{key:index,style:(("top: " + (_vm.vlData.topPosition) + "px")),attrs:{"title":item.name1,"subtitle":item.address1 + ', ' + item.postalcode + ' ' + item.place},on:{"click":function($event){return _vm.addCustomer(item.id)}}})})],2)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }