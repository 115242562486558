<template>
	<f7-page name="home">
		<f7-navbar no-shadow title="Bestellübersicht" back-link=""> </f7-navbar>

		<!-- Show if cart is empty -->
		<f7-block v-if="$root.$data.order.cart.length === 0">
			<p>
				<center>
					Die Liste enthält keine Einträge.
					<br /><br />
					Mit dem + Symbol unten rechts kannst du Bestellpositionen hinzufügen.
				</center>
			</p>
		</f7-block>

		<!-- Show if cart is not empty -->
		<shoppingCart></shoppingCart>

		<!-- Manual Scan: Invoke internal camera app -->
		<input
			type="file"
			id="manualScan"
			capture
			accept="image/*"
			style="display: none"
			@change="manualScanFile"
		/>

		<!-- FAB Right Bottom -->
		<f7-fab
			position="right-bottom"
			slot="fixed"
			color="blue"
			style="margin-right: 10px; margin-bottom: 10px;"
		>
			<f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
			<f7-icon ios="f7:xmark" aurora="f7:xmark" md="material:close"></f7-icon>
			<f7-fab-buttons position="top">
				<f7-fab-button @click="$f7router.navigate('/category/')">
					<f7-icon
						size="20"
						ios="f7:rectangle_stack_fill"
						aurora="f7:rectangle_stack_fill"
						md="f7:rectangle_stack_fill"
					></f7-icon>
				</f7-fab-button>
				<f7-fab-button @click="$f7router.navigate('/new/')">
					<f7-icon
						size="20"
						ios="f7:star_fill"
						aurora="f7:star_fill"
						md="f7:star_fill"
					></f7-icon>
				</f7-fab-button>
				<f7-fab-button
					v-if="!settings.manualScan"
					@click="$f7router.navigate('/scan/')"
				>
					<f7-icon
						size="20"
						ios="f7:barcode"
						aurora="f7:barcode"
						md="f7:barcode"
					></f7-icon>
				</f7-fab-button>
				<f7-fab-button v-if="settings.manualScan" @click="manualScanStart">
					<f7-icon
						size="20"
						ios="f7:barcode"
						aurora="f7:barcode"
						md="f7:barcode"
					></f7-icon>
				</f7-fab-button>
				<f7-fab-button
					@click="
						$f7router.navigate('/manual/', {
							props: { products },
						})
					"
				>
					<f7-icon
						size="20"
						ios="f7:search"
						aurora="f7:search"
						md="f7:search"
					></f7-icon>
				</f7-fab-button>
			</f7-fab-buttons>
		</f7-fab>
	</f7-page>
</template>

<script>
// Import components
import shoppingCart from "../components/shoppingCart";

// Import Quagga scanner
import Quagga from "quagga";

const Entities = require("html-entities").AllHtmlEntities;
const entities = new Entities();

export default {
	data() {
		return {
			products: null,
			showLogin: true,
			settings: { manualScan: false, liveScan: true, externalScan: false },
		};
	},
	props: ["title"],

	components: {
		shoppingCart,
	},

	methods: {
		manualScanStart: function() {
			let fileElem = document.getElementById("manualScan");
			console.log("manual scan clicked...");
			fileElem.click();
		},
		manualScanFile: async function() {
			let _this = this;
			let file = document.getElementById("manualScan").files[0];
			let data = await loadImage(file, {
				canvas: true,
				maxWidth: 1600,
				orientation: 6,
			});
			Quagga.decodeSingle({
				inputStream: {
					type: "ImageStream",
				},
				locate: true,
				decoder: {
					readers: ["ean_reader"],
				},
				locator: {
					patchSize: "medium",
					halfSample: true,
				},
				src: data.image.toDataURL("image/jpeg"),
			});
			Quagga.onProcessed(function(result) {
				console.log(result);
				try {
					console.log("adding to cart...");
					Quagga.stop();
					const product = _this.$root.$data.products.find(function(product) {
						return product.barcode === result.codeResult.code;
					});
					if (typeof product == "undefined") {
						_this.showMessage(
							"Kein Produkt mit diesem Barcode vorhanden! ",
							"error"
						);
					} else {
						_this.$f7router.navigate(
							"/addCart/" + encodeURIComponent(product.id) + "/"
						);
					}
				} catch {
					Quagga.stop();
					_this.showMessage("Barcode konnte nicht gelesen werden", "error");
				}
			});
		},
		removeByAttr: function(arr, attr, value) {
			var i = arr.length;
			while (i--) {
				if (
					arr[i] &&
					arr[i].hasOwnProperty(attr) &&
					arguments.length > 2 &&
					arr[i][attr] === value
				) {
					arr.splice(i, 1);
				}
			}
			return arr;
		},
		deleteFromCart: function(id) {
			// Get current cart
			let cart = JSON.parse(localStorage.getItem("cart"));
			if (cart == null) {
				this.$root.$data.order.cart = [];
			}
			// Prepare item
			console.log("deleting: " + id);

			// // Delete item
			this.removeByAttr(cart, "id", id);
			localStorage.setItem("cart", JSON.stringify(cart));
			this.$root.$data.order.cart = cart;
		},
	},

	mounted() {
		// Read settings from storage
		this.settings = this.$root.$data.settings;
	},
};
</script>

<style scoped>
.list {
	margin-top: 0px;
}
ul::before {
	height: 0px;
}
p {
	margin-top: 50% !important;
	color: #959aa3;
}
.page-content {
	background-color: white;
}
</style>
<style>
html,
body {
	overflow: hidden;
	position: fixed;
	overscroll-behavior-y: contain;
	overscroll-behavior: contain;
}
.toast-text {
	margin: auto;
}
.md {
	--f7-fab-size: 48px;
	--f7-fab-extended-size: 48px;
}
</style>
