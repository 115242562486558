<template>
	<f7-page title="External Scan" name="externalScan">
		<f7-navbar back-link="Back"></f7-navbar>
		<img src="/static/barcode.png" class="barcode" />
		<f7-block>
			<p>
				<center>
					Bitte den externen Barcode
					<br />
					Scanner verwenden.
				</center>
			</p>
		</f7-block>
	</f7-page>
</template>

<script>
export default {
	props: ["products"],

	methods: {
		getProduct: function(eanCode) {
			console.log(eanCode);

			const product = this.$root.$data.products.find(function(product) {
				return product.barcode === eanCode;
			});

			if (product) {
				this.$f7router.navigate(
					"/addCart/" + encodeURIComponent(product.id) + "/"
				);
			} else {
				this.showMessage(
					"Kein Produkt mit diesem EAN-Code vorhanden!",
					"error"
				);
			}
		},
	},

	mounted() {
		let _this = this;

		let scannerInput = "";
		let lastClear = 0;

		document.body.addEventListener(
			"keydown",
			function(e) {
				clearTimeout(lastClear);
				lastClear = window.setTimeout(() => {
					scannerInput = "";
				}, 500);

				if (e.key !== "Enter" && !isNaN(e.key)) {
					scannerInput += e.key;
					console.log(scannerInput);
				}

				if (scannerInput.length === 13) {
					_this.getProduct(scannerInput);
					console.log("13-digits: " + scannerInput);
					scannerInput = "";
				}
			},
			true
		);
	},
};
</script>

<style>
p {
	color: #959aa3;
}
</style>
