<template>
	<f7-page name="orderOverview">
		<!-- Navbar -->
		<f7-navbar title="Zusammenfassung" back-link> </f7-navbar>

		<!-- Selected delivera address -->
		<f7-block-title>Lieferadresse</f7-block-title>
		<f7-block strong>
			{{ customer.name1 }}
			<br />
			{{ customer.address1 }}
			<br />
			{{ customer.postalcode }} {{ customer.place }}
		</f7-block>

		<!-- Bestellung -->
		<f7-block-title>Artikel</f7-block-title>
		<f7-list>
			<f7-list-item
				title="Anzahl verschiedener Artikel"
				:after="articleCount"
			></f7-list-item>
			<f7-list-item
				title="Stückzahl sämtlicher Artikel"
				:after="articleCountSub"
			></f7-list-item>
			<f7-list-item
				title="Artikel im Detail"
				link=""
				@click="$f7router.navigate('/orderOverviewCart/')"
			></f7-list-item>
		</f7-list>

		<!-- Delivery options -->
		<span v-if="$root.$data.order.deliveryDate">
			<f7-block-title>Versanddatum</f7-block-title>
			<f7-block strong>
				{{ $root.$data.order.deliveryDate }}
			</f7-block>
		</span>

		<!-- Delivery options -->
		<span
			v-if="
				$root.$data.order.deliveryDateMandatory ||
					$root.$data.order.deliveryWithMagnets
			"
		>
			<f7-block-title>Bestelloptionen</f7-block-title>
			<f7-block strong>
				<span v-if="$root.$data.order.deliveryDateMandatory">
					- Versanddatum {{ $root.$data.order.deliveryDate }} zwingend einhalten
				</span>
				<br
					v-if="
						$root.$data.order.deliveryWithMagnets &&
							$root.$data.order.deliveryDateMandatory
					"
				/>
				<span v-if="$root.$data.order.deliveryWithMagnets">
					- Vorzeigekarten mit Magneten liefern
				</span>
			</f7-block>
		</span>

		<!-- E-Mail confirmation to customer -->
		<span v-if="$root.$data.order.customerEmail">
			<f7-block-title
				>Bestätigung an folgende Kunden-E-Mail Adresse:</f7-block-title
			>
			<f7-block strong>
				{{ $root.$data.order.customerEmail }}
			</f7-block>
		</span>

		<!-- Comment -->
		<span v-if="$root.$data.order.orderText">
			<f7-block-title>Kommentar</f7-block-title>
			<f7-block strong>
				{{ $root.$data.order.orderText }}
			</f7-block>
		</span>

		<!-- Send order -->
		<f7-toolbar
			bottom
			:inner="false"
			no-shadow
			no-hairline
			class="toolbar-custom"
		>
			<f7-button fill large @click="sendOrder" class="button-custom"
				>Jetzt Bestellen</f7-button
			>
		</f7-toolbar>
	</f7-page>
</template>
<script>
const Entities = require("html-entities").AllHtmlEntities;
const entities = new Entities();

export default {
	data() {
		return {
			customer: "",
			articleCount: 0,
			articleCountSub: 0,
			placeOrder: [{ customer: {} }, { products: [] }],
		};
	},

	methods: {
		sendOrder: function() {

			// Check if online
			if (!navigator.onLine) {
				this.showMessage(
					"Bestellung absenden nicht möglich - Du bist offline!",
					"error"
				);
				return;
			}

			// Preparing order
			console.log("sending order...");
			this.placeOrder.customer = this.customer;

			// Send order to API
			let body =
				'{"action":"postOrder", "username": "' +
				this.$root.$data.credentials.username +
				'", "password":"' +
				this.$root.$data.credentials.password +
				'", "customer":' +
				JSON.stringify(this.placeOrder.customer) +
				', "customerEmail":' +
				JSON.stringify(this.$root.$data.order.customerEmail) +
				', "deliveryDate":"' +
				this.$root.$data.order.deliveryDate +
				'", "deliveryDateMandatory":"' +
				this.$root.$data.order.deliveryDateMandatory +
				'", "deliveryWithMagnets":"' +
				this.$root.$data.order.deliveryWithMagnets +
				'", "orderText":' +
				JSON.stringify(this.$root.$data.order.orderText) +
				', "cart":' +
				JSON.stringify(this.$root.$data.order.cart) +
				', "env":' +
				JSON.stringify(this.$root.$data.env) +
				"}";

			this.$f7.dialog.preloader("Sende Bestellung...");

			debugger;

			fetch(this.$root.$data.endpoint + "/admin/", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: "A5C2AD00B7",
				},
				cache: "default",
				body: body,
			})
				.then((response) => response.json())
				.then((data) => {
					if (data.status === "ok") {
						setTimeout(() => {
							this.$f7.dialog.close();
							let success = this.$f7.dialog.alert(
								"Die Bestellung wurde erfolgreich versendet!",
								"Erfolgreich versendet"
							);
							localStorage.removeItem("cart");
							this.$root.$data.order.cart = [];
							this.$root.$emit("refreshCart");
							this.$root.$data.order.customerId = "";
							this.$root.$data.order.deliveryDate = "";
							this.$root.$data.order.deliveryDateMandatory = "";
							this.$root.$data.order.deliveryWithMagnets = "";
							this.$root.$data.order.orderText = "";
							this.$root.$data.order.customerEmail = "";
							this.$f7router.navigate("/", {
								transition: "f7-dive",
								reloadAll: true,
							});
						}, 1500);

						console.log("ok");
					} else {
						setTimeout(() => {
							this.$f7.dialog.close();
						}, 1500);
						this.$f7.dialog.alert(
							"Es ist ein technischer Fehler aufgetreten. Die Bestellung kann nicht versendet werden!",
							"Fehler"
						);
						console.log("nok");
					}
				});
		},
	},

	beforeMount() {
		console.log("Getting customer data...");
		const _this = this;
		let customerId = this.$root.$data.order.customerId;
		this.$root.$data.customers.forEach((element) => {
			if (element.id === customerId) {
				_this.customer = element;
			}
		});
	},

	mounted() {
		let _this = this;

		// Count articles
		if (this.$root.$data.order.cart) {
			this.articleCount = this.$root.$data.order.cart.length;
		}

		// Count no. of products

		this.$root.$data.order.cart.forEach((article) => {
			_this.articleCountSub += parseInt(article.qty);
		});

		// Refresh product details
		this.$root.$on("refreshOrderOverview", function() {
			// Count articles
			if (_this.$root.$data.order.cart) {
				_this.articleCount = this.$root.$data.order.cart.length;
			}
			// Count no. of products
			_this.articleCountSub = 0;
			_this.$root.$data.order.cart.forEach((article) => {
				_this.articleCountSub += article.qty;
			});
		});
	},
};
</script>

<style>
.toolbar-custom {
	height: 80px;
	padding: 15px 0 15px 0;
	background-color: rgb(242, 242, 242);
}
.button-custom {
	margin-top: 0px !important;
}
</style>
