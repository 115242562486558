<template>
	<f7-page name="manual">
		<!-- Navbar -->
		<f7-navbar title="Zusätzliche Angaben" back-link> </f7-navbar>
		<f7-block-title>Versanddatum</f7-block-title>
		<f7-list no-hairlines-md>
			<f7-list-input
				type="text"
				placeholder="Versanddatum"
				clear-button
				id="calendar"
				:value="deliveryDate"
				@input:clear="setDate('')"
			>
			</f7-list-input>
		</f7-list>
		<f7-block-title>Bestelloptionen</f7-block-title>
		<f7-list no-hairlines-md>
			<f7-list-item
				checkbox
				@change="setDateMandatory"
				title="Versanddatum zwingend einhalten"
			></f7-list-item>
			<f7-list-item
				checkbox
				@change="setDeliveryWithMagnets"
				title="Vorzeigekarten mit Magneten liefern"
			></f7-list-item>
		</f7-list>
		<f7-block-title>E-Mail Bestätigung</f7-block-title>
		<f7-list no-hairlines-md>
			<f7-list-item
				checkbox
				@change="customerEmailCheckbox = !customerEmailCheckbox"
				title="E-Mail Bestätigung an Kunde senden"
			></f7-list-item>
			<f7-list-input
				v-if="customerEmailCheckbox"
				type="text"
				inputmode
				clear-button
				placeholder="kunde@kunde.ch"
				:value="customerEmail"
				@change="setCustomerEmail"
			>
			</f7-list-input>
		</f7-list>
		<f7-block-title>Bemerkung</f7-block-title>
		<f7-list no-hairlines-md>
			<f7-list-input
				type="textarea"
				inputmode
				placeholder="Info für Office Hervorragend AG"
				:value="orderText"
				@change="setOrderText"
			>
			</f7-list-input>
		</f7-list>

		<!-- Proceed button -->
		<f7-toolbar
			bottom
			:inner="false"
			class="toolbar-custom"
			no-shadow
			no-hairline
		>
			<f7-button fill large @click="placeOrder" class="button-custom"
				>Weiter</f7-button
			>
		</f7-toolbar>
	</f7-page>
</template>
<script>
const Entities = require("html-entities").AllHtmlEntities;
const entities = new Entities();

export default {
	data() {
		return {
			customer: "",
			deliveryDate: "",
			deliveryDateMandatory: false,
			deliverWithMagnets: false,
			orderText: "",
			customerEmailCheckbox: false,
			customerEmail: "",
		};
	},
	props: [""],

	methods: {
		setDate: function(date) {
			this.$root.$data.order.deliveryDate = date;
			this.deliveryDate = date;
		},
		setDateMandatory: function() {
			this.$root.$data.order.deliveryDateMandatory = !this
				.deliveryDateMandatory;
			this.deliveryDateMandatory = !this.deliveryDateMandatory;
			console.log("setDateMandatory run complete...");
		},
		setDeliveryWithMagnets: function() {
			this.$root.$data.order.deliveryWithMagnets = !this.deliveryWithMagnets;
			this.deliveryWithMagnets = !this.deliveryWithMagnets;
			console.log("setDeliveryWithMagnets run complete...");
		},
		getDate: function() {
			this.$f7.calendar.get().setValue(this.$root.data.order.deliveryDate);
		},
		deleteDate: function() {
			this.deliveryDate = "";
		},
		setCustomerEmail: function(email) {
			this.$root.$data.order.customerEmail = email.target.value;
			this.customerEmail = email.target.value;
		},
		setOrderText: function(text) {
			this.$root.$data.order.orderText = text.target.value;
			this.orderText = text.target.value;
		},
		placeOrder: function() {
			// Validate date
			if (this.deliveryDateMandatory === true && this.deliveryDate === "") {
				this.showMessage("Bitte ein Versanddatum angeben!", "error");
				return;
			}

			// Validate & save email
			if (this.customerEmailCheckbox) {
				let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				if (!emailRegex.test(String(this.customerEmail).toLowerCase())) {
					this.showMessage("Bitte E-Mail Adresse des Kunden angeben!", "error");
					return;
				}
				this.$root.$data.order.customerEmail = this.customerEmail;
			}

			this.$f7router.navigate("/orderOverview/");
		},
	},

	mounted() {
		// Calendar
		let _this = this;
		let fromDate = new Date(0);
		let toDate = new Date();
		toDate.setDate(toDate.getDate() + 0); // Add X Days
		this.$f7.calendar.create({
			inputEl: "#calendar",
			on: {
				change: function(value) {
					let setDate = new Date(value.getValue());
					if (isNaN(setDate)) {
						_this.setDate("");
						return;
					}
					let day = "0" + setDate.getDate().toString();
					day = day.slice(-2);
					let month = "0" + (setDate.getMonth() + 1);
					month = month.slice(-2);
					let year = setDate.getFullYear();
					let fullDate = day + "." + month + "." + year;
					console.log(fullDate);
					_this.setDate(fullDate);
				},
			},
			disabled: {
				from: fromDate,
				to: toDate,
			},
		});

		// Get Customer Email
		let customerId = this.$root.$data.order.customerId;
		let customer = this.$root.$data.customers.find(
			(customer) => customer.id === customerId
		);
		if (typeof customer !== "undefined") {
			this.customerEmail = customer.email;
		}
	},

	computed: {},
};
</script>

<style>
.toolbar-custom {
	height: 80px;
	padding: 15px 0 15px 0;
	background-color: rgb(242, 242, 242);
}
.button-custom {
	margin-top: 0px !important;
}
</style>
