<template>
	<f7-page name="manual">
		<!-- Navbar -->
		<f7-navbar title="Produkt Suchen" back-link>
			<f7-subnavbar :inner="false">
				<f7-searchbar
					class="searchbar"
					placeholder="Suchen"
					:custom-search="true"
					:disable-button="false"
					@searchbar:search="setSearchString"
				></f7-searchbar>
			</f7-subnavbar>
		</f7-navbar>
		<!-- Product list / search results -->
		<f7-list media-list>
			<f7-list-item
				v-for="(product, index) in filteredProducts"
				:key="index"
				:title="decode(product.name)"
				:subtitle="product.id + ' / ' + product.price"
				:link="`/addCart/${encodeURIComponent(product.id)}/`"
			>
				<div
					v-if="!$f7.online"
					class="skeleton-block"
					style="width: 44px; height: 44px;"
					slot="media"
				></div>
				<img v-if="$f7.online" slot="media" :src="product.img" width="44" />
			</f7-list-item>
		</f7-list>
	</f7-page>
</template>
<script>
const Entities = require("html-entities").AllHtmlEntities;
const entities = new Entities();

export default {
	data() {
		return {
			productsFiltered: [],
			searchString: "",
		};
	},
	props: ["products"],

	methods: {
		setSearchString(searchbar, query) {
			this.searchString = query;
		},
		decode(string) {
			return entities.decode(string);
		},
	},
	computed: {
		filteredProducts: function() {
			let searchString = this.searchString;
			if (searchString.length > 3) {
				console.log("filtering...");
				return this.$root.$data.products.filter(function(product) {
					let findName = product.name
						.toLowerCase()
						.includes(searchString.toLowerCase());
					let findId = product.id
						.toLowerCase()
						.includes(searchString.toLowerCase());
					if (findName || findId) return true;
				});
			} else {
				return [];
			}
		},
	},
};
</script>

<style scoped>
.list {
	margin-top: 0px;
}
</style>
