<template>
    <f7-app :params="f7params">
        <!-- Views container -->
        <f7-views tabs class="safe-areas">
            <!-- Your main view/tab, should have "view-main" class. It also has "tab-active" class -->
            <f7-view id="view-home" main tab tab-active url="/"></f7-view>
        </f7-views>
    </f7-app>
</template>
<script>
import routes from "../js/routes.js";

export default {
    data() {
        return {
            // Framework7 Parameters
            f7params: {
                name: "Hervorragend", // App name
                theme: "auto", // Automatic theme detection
                iosTranslucentBars: false,
                serviceWorker: {
                    path: "./serviceworker.js",
                    scope: "/",
                },

                // App routes
                routes: routes,
            },
        };
    },
};
</script>
