<template>
	<f7-page name="category" class="categorydetail-page-content">
		<f7-navbar :title="categoryTitle" back-link></f7-navbar>
		<f7-subnavbar :inner="false">
			<f7-searchbar
				placeholder="suchen"
				search-container=".virtual-list"
				search-item="li"
				search-in=".item-title"
				:disable-button="false"
			></f7-searchbar>
		</f7-subnavbar>
		<!-- Product list / search results -->
		<f7-list
			class="categorydetail-media-list"
			media-list
			virtual-list
			:virtual-list-params="{
				items,
				searchAll,
				renderExternal,
				height: $theme.ios ? 74 : $theme.md ? 73 : 46,
			}"
		>
			<ul>
				<f7-list-item
					v-for="(item, index) in vlData.items"
					:key="index"
					:title="decode(item.name)"
					:subtitle="item.id + ' / ' + item.price"
					:style="`top: ${vlData.topPosition}px`"
					:link="`/addCart/${encodeURIComponent(item.id)}/`"
				>
					<div
						v-if="!$f7.online"
						class="skeleton-block"
						style="width: 44px; height: 44px;"
						slot="media"
					></div>
					<img v-if="$f7.online" slot="media" :src="item.img" width="44" />
				</f7-list-item>
			</ul>
		</f7-list>
	</f7-page>
</template>

<script>
const Entities = require("html-entities").AllHtmlEntities;
const entities = new Entities();

export default {
	data() {
		return {
			items: [],
			vlData: {
				items: [],
			},
			categoryTitle: "",
		};
	},
	props: ["category"],

	methods: {
		decode(string) {
			debugger;
			return entities.decode(string);
		},
		searchAll(query, items) {
			debugger;
			const found = [];
			for (let i = 0; i < items.length; i += 1) {
				if (
					items[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0 ||
					items[i].id.toLowerCase().indexOf(query.toLowerCase()) >= 0 ||
					query.trim() === ""
				)
					found.push(i);
			}
			return found;
		},
		renderExternal(vl, vlData) {
			this.vlData = vlData;
		},
	},

	beforeMount() {
		const _this = this;
		debugger;
		this.items = this.$root.$data.products.filter(
			(product) => product.category === _this.category
		);
		// debugger;
		// if (this.category === "Jaab-fromJude Karte") {
		//     this.categoryTitle = "Jaab/fromJude Karte"
		// }
		// else {
		//     this.categoryTitle = this.category
		// }
	},
};
</script>

<style>
.list {
	margin-top: 0px;
}
.ios {
	--f7-searchbar-backdrop-bg-color: #ffffff !important;
}
.categorydetail-page-content {
	background-color: white;
}

.categorydetail-media-list ul:after {
	content: initial;
}

.categorydetail-media-list ul li:last-of-type .item-inner {
	border-bottom: 1px solid #e0e0e0;
}
</style>
