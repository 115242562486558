<template>
	<f7-page name="settings">
		<f7-navbar
			title="Informationen"
			back-link
			back-link-force
			@click:back="reload"
		></f7-navbar>

		<f7-block-title>Bestellungen</f7-block-title>
		<f7-list>
			<f7-list-item
				title="Anzahl Artikel in Liste"
				:after="articleCount"
			></f7-list-item>
			<f7-list-item v-if="articleCount > 0"
				><f7-link @click="deleteCart"
					>Alle Artikel löschen</f7-link
				></f7-list-item
			>
		</f7-list>

		<f7-block-title>Kunden</f7-block-title>
		<f7-list>
			<f7-list-item
				title="Kundenliste"
				link=""
				@click="$f7router.navigate('/showCustomer/')"
			></f7-list-item>
		</f7-list>

		<f7-block-title>Barcode scannen</f7-block-title>
		<!-- <f7-list>
			<f7-list-item>
				<span>Manuell mit Foto</span>
				<f7-toggle
					:checked="$root.$data.settings.manualScan"
					@change="setManualScan"
				></f7-toggle>
			</f7-list-item>
		</f7-list> -->
		<f7-list>
			<f7-list-item
				:checked="settings.liveScan"
				@change="setScanMode('liveScan')"
				radio
				title="Live Kamera"
			></f7-list-item>
			<f7-list-item
				:checked="settings.manualScan"
				@change="setScanMode('manualScan')"
				radio
				title="Foto"
			></f7-list-item>
			<f7-list-item
				:checked="settings.externalScan"
				@change="setScanMode('externalScan')"
				radio
				title="Externer Scanner"
			></f7-list-item>
		</f7-list>

		<f7-block-title>Anmeldung</f7-block-title>
		<f7-list>
			<f7-list-item
				title="Benutzername"
				:after="$root.$data.credentials.username"
			></f7-list-item>
			<f7-list-item
				title="Letze Anmeldung"
				:after="dateFormatted"
			></f7-list-item>
			<f7-list-item><f7-link @click="signOff">Abmelden</f7-link></f7-list-item>
		</f7-list>

		<f7-block-title>App Information</f7-block-title>
		<f7-list>
			<f7-list-item
				title="Version"
				:after="$root.$data.appInfo.version"
			></f7-list-item>
			<f7-list-item
				title="Letze Aktualisierung"
				:after="$root.$data.appInfo.date"
			></f7-list-item>
			<f7-list-item
				v-if="$root.$data.appInfo.updateVersion != ''"
				title="Update verfügbar"
				:after="$root.$data.appInfo.updateVersion"
			></f7-list-item>
			<f7-list-item v-if="$root.$data.appInfo.updateVersion != ''"
				><f7-link @click="installUpdate"
					>Update installieren</f7-link
				></f7-list-item
			>
		</f7-list>
	</f7-page>
</template>

<script>
export default {
	data() {
		return {
			dateFormatted: "",
			articleCount: "",
			isIos: false,
			settings: {
				liveScan: true,
				manualScan: false,
				externalScan: false,
			},
			forceReload: false,
		};
	},
	props: ["appInfo", "cart"],

	methods: {
		reload: function() {
			console.log("forcing reload");
			if (this.forceReload == true) {
				window.location.reload({
					forceReload: true,
				});
			}
		},
		signOff: function() {
			const self = this;
			const app = self.$f7;
			if (!self.actionsToPopover) {
				self.actionsToPopover = app.actions.create({
					buttons: [
						{
							text:
								"Beim Abmelden werden alle Artikel in der Bestellliste gelöscht. Bitte bestätigen.",
							label: true,
						},
						{
							text: "Bestätigen",
							onClick: function() {
								localStorage.clear();
								self.$root.$data.credentials.username = "";
								self.$root.$data.credentials.password = "";
								self.$root.$data.credentials.lastLogin = "";
								self.$root.$data.lastApiGet = "";
								self.$f7router.navigate("/", {
									reloadAll: true,
								});
							},
						},
						{
							text: "Abbrechen",
							color: "red",
						},
					],
				});
			}

			// Open
			self.actionsToPopover.open();
		},
		deleteCart: function() {
			const self = this;
			const app = self.$f7;
			if (!self.actionsToPopover) {
				self.actionsToPopover = app.actions.create({
					buttons: [
						{
							text:
								"Es werden alle Artikel aus der Liste entfernt! Bitte bestätigen.",
							label: true,
						},
						{
							text: "Bestätigen",
							onClick: function() {
								localStorage.removeItem("cart");
								self.$root.$emit("refreshCart");
								self.$f7router.back();
							},
						},
						{
							text: "Abbrechen",
							color: "red",
						},
					],
				});
			}

			// Open
			self.actionsToPopover.open();
		},
		installUpdate: function() {
			const _this = this;
			if ("serviceWorker" in navigator) {
				navigator.serviceWorker
					.getRegistrations()
					.then(function(registrations) {
						for (let registration of registrations) {
							registration.unregister().then((result) => {
								console.log("Serviceworker unregistered: " + result);
								_this.$f7.dialog.preloader("Update installieren...");
								navigator.serviceWorker
									.register("./serviceworker.js")
									.then(() => {
										console.log("Serviceworker registered: " + result);
										setTimeout(() => {
											_this.$f7.dialog.close();
											_this.$root.$destroy();
											window.location.reload({
												forceReload: true,
											});
										}, 3000);
									});
							});
						}
					});
			}
		},
		// setManualScan: function() {
		// 	console.log("setting manual scan");
		// 	// Force reload?
		// 	if (this.settings.manualScan == true) {
		// 		this.forceReload = true;
		// 	}

		// 	this.settings.manualScan = !this.settings.manualScan;
		// 	this.$root.$data.settings.manualScan = this.settings.manualScan;
		// 	localStorage.setItem("settings", JSON.stringify(this.settings));
		// 	if (navigator.onLine) {
		// 		this.postSettings();
		// 	}
		// },
		setScanMode: function(scanMode) {
			if (scanMode === "liveScan") {
				this.settings.liveScan = true;
				this.settings.manualScan = false;
				this.settings.externalScan = false;
			}
			if (scanMode === "manualScan") {
				this.settings.liveScan = false;
				this.settings.manualScan = true;
				this.settings.externalScan = false;
			}
			if (scanMode === "externalScan") {
				this.settings.liveScan = false;
				this.settings.manualScan = false;
				this.settings.externalScan = true;
			}

			// Save settings to root, localstorage and kirby
			this.$root.$data.settings = this.settings;
			localStorage.setItem("settings", JSON.stringify(this.settings));

			if (navigator.onLine) {
				this.postSettings();
			}
		},
	},

	mounted() {
		const _this = this;

		// Format date
		console.log("reading credentials");
		if (this.$root.$data.credentials) {
			let dateRaw = new Date(this.$root.$data.credentials.lastLogin);
			let dateFormatted = dateRaw.toLocaleString();
			this.dateFormatted = dateFormatted;
		}

		// Count articles
		if (this.$root.$data.order.cart) {
			this.articleCount = this.$root.$data.order.cart.length;
			if (this.articleCount === 0) {
				this.articleCount = "0";
			}
		}

		// Check if new app version needs to be installed
		console.log("checking app version...");
		if (
			navigator.onLine &&
			(this.$f7.device.ios === true || this.$f7.device.android === true)
		) {
			this.$request.json(
				this.$root.$data.endpoint + "/api/checkversion.php",
				function(result) {
					if (result.version != _this.$root.$data.appInfo.version) {
						console.log("New app version available...");
						_this.$root.$data.appInfo.updateVersion = result.version;
					} else {
						_this.$root.$data.appInfo.updateVersion = "";
					}
				}
			);
		}

		// Get settings
		this.settings = this.$root.$data.settings;
	},
};
</script>
