<template>
	<f7-page title="Scan" name="scan" @page:afterin="startQuagga">
		<f7-navbar back-link="Back" @click:back="stopQuagga"></f7-navbar>
		<f7-block-title>Barcode einlesen</f7-block-title>
		<f7-block
			strong
			style="position: relative;"
			id="scanWindow"
			class="scanWindow"
		>
		</f7-block>
		<img src="/static/barcode.png" class="barcode" />
	</f7-page>
</template>

<script>
// Import Quagga scanner
import Quagga from "quagga";

export default {
	data() {
		return {
			test: "",
		};
	},

	props: ["products"],

	mounted() {},

	methods: {
		checkBarcode: function(barcode) {
			return this.$root.$data.products.barcode === barcode;
		},
		startQuagga: function() {
			console.log("starting...");
			let _this = this;

			Quagga.init(
				{
					inputStream: {
						name: "Live",
						type: "LiveStream",
						target: document.querySelector("#scanWindow"),
						constraints: {
							width: 1000, // 500
							height: 1600, // 800
							facingMode: "environment",
						},
					},
					decoder: {
						readers: ["ean_reader"],
						debug: {
							showCanvas: true,
							showPatches: true,
							showFoundPatches: true,
							showSkeleton: true,
							showLabels: true,
							showPatchLabels: true,
							showRemainingPatchLabels: true,
							boxFromPatches: {
								showTransformed: true,
								showTransformedBox: true,
								showBB: true,
							},
						},
					},
				},
				function(err) {
					if (err) {
						console.log(err);
						return;
					}

					console.log("Initialization finished. Ready to start");
					Quagga.start();
				}
			);

			Quagga.onProcessed(function(result) {
				var drawingCtx = Quagga.canvas.ctx.overlay,
					drawingCanvas = Quagga.canvas.dom.overlay;

				if (result) {
					if (result.boxes) {
						drawingCtx.clearRect(
							0,
							0,
							parseInt(drawingCanvas.getAttribute("width")),
							parseInt(drawingCanvas.getAttribute("height"))
						);
						result.boxes
							.filter(function(box) {
								return box !== result.box;
							})
							.forEach(function(box) {
								Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
									color: "green",
									lineWidth: 2,
								});
							});
					}

					if (result.box) {
						Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
							color: "#00F",
							lineWidth: 2,
						});
					}

					if (result.codeResult && result.codeResult.code) {
						Quagga.ImageDebug.drawPath(
							result.line,
							{ x: "x", y: "y" },
							drawingCtx,
							{
								color: "red",
								lineWidth: 3,
							}
						);
					}
				}
			});

			Quagga.onDetected(function(result) {
				console.log("Searching EAN code...");
				const product = _this.$root.$data.products.find(function(product) {
					return product.barcode === result.codeResult.code;
				});
				if (product) {
					let _product = product;
					_this.Dom7(".scanWindow").addClass("scanSuccess");
					setTimeout(function() {
						_this.$f7router.navigate(
							"/addCart/" + encodeURIComponent(_product.id) + "/"
						);
						Quagga.stop();
						_this.Dom7(".scanWindow").removeClass("scanSuccess");
					}, 300);
				}
			});
		},
		stopQuagga: function() {
			Quagga.stop();
			console.log("stopping...");
		},
	},
};
</script>

<style>
/* In order to place the tracking correctly */
canvas.drawing,
canvas.drawingBuffer {
	position: absolute;
	left: 0;
	top: 0;
	max-width: 100%;
	width: 100%;
}
video {
	width: 100%;
}
.barcode {
	width: 200px;
	opacity: 0.2;
	display: block;
	margin-top: 100px;
	margin-left: auto;
	margin-right: auto;
	width: 50%;
}
.scanWindow {
	background-color: #e6e6e6;
}
.scanSuccess {
	background-color: #4caf50;
}
</style>
