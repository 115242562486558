<template>
    <f7-list media-list v-if="$root.$data.order.cart.length > 0" class="shopping-list">
        <f7-list-item
            v-for="(product, index) in $root.$data.order.cart"
            swipeout
            @swipeout:deleted="deleteFromCart(product.id)"
            :key="product.id"
            :title="decode(product.name)"
            :footer="
                product.id + (typeof product.price != 'undefined' ? ' / ' + product.price : '')
            "
            :link="
                `/updateCart/${encodeURIComponent(product.id)}/${encodeURIComponent(
                    product.qty
                )}/${encodeURIComponent(product.sampleQty)}/${encodeURIComponent(product.barcode)}
                    /${index}/`
            "
            :badge="product.qty"
        >
            <div
                v-if="!$f7.online"
                class="skeleton-block"
                style="width: 44px; height: 44px;"
                slot="media"
            ></div>
            <img v-if="$f7.online" slot="media" :src="product.imageUrl" width="44" />
            <f7-swipeout-actions right>
                <f7-swipeout-button delete>Löschen</f7-swipeout-button>
            </f7-swipeout-actions>
        </f7-list-item>
    </f7-list>
</template>

<script>
const Entities = require("html-entities").AllHtmlEntities;
const entities = new Entities();

export default {
    name: "shoppingCart",
    methods: {
        removeByAttr: function(arr, attr, value) {
            var i = arr.length;
            while (i--) {
                if (
                    arr[i] &&
                    arr[i].hasOwnProperty(attr) &&
                    arguments.length > 2 &&
                    arr[i][attr] === value
                ) {
                    arr.splice(i, 1);
                }
            }
            return arr;
        },
        deleteFromCart: function(id) {
            // Get current cart
            let cart = JSON.parse(localStorage.getItem("cart"));
            if (cart == null) {
                this.$root.$data.order.cart = [];
            }

            // // Delete item
            this.removeByAttr(cart, "id", id);
            localStorage.setItem("cart", JSON.stringify(cart));
            this.$root.$data.order.cart = cart;
            this.$root.$emit("refreshOrderOverview");
        },
        decode(string) {
            return entities.decode(string);
        },
    },
    mounted() {
        let _this = this;
        // Refreshing cart from storage
        this.$root.$on("refreshCart", function() {
            let cart = JSON.parse(localStorage.getItem("cart"));
            if (cart == null) {
                cart = [];
            }
            _this.$root.$data.order.cart = cart;
        });
    },
};
</script>

<style scoped>
.shopping-list >>> ul:after {
    height: 0px;
}
</style>
